import {Component, HostBinding, Input, NgZone} from '@angular/core';
import {HoverAction} from '../../hover-action.interface';
import {of, Subject} from 'rxjs';
import {flyInTrigger} from '../../../../../@theme/animations';
import {UserAuthService} from '../../../../../@core/user-auth.service';
import {User} from '../../../../../models/user';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeComponent} from '../../../../../@core/fc-component';
import {MethodCache} from '../../../../../utils/accessor.utils';

@Component({
    selector: 'hover-action-icons',
    templateUrl: './hover-action-icons.component.html',
    styleUrls: ['./hover-action-icons.component.scss'],
    animations: [flyInTrigger],
})
export class HoverActionIconsComponent<T = any> extends UnsubscribeComponent {
    @HostBinding('@flyIn') visible = true;
    @HostBinding('class') get cssClass() {
        return `hover-action-icons position-${this.position}`;
    }

    @Input() actions: HoverAction<T>[];
    @Input() data: T;
    @Input() position: string;
    @Input() selfDestroy$: Subject<void>;

    private user: User;

    constructor(private userAuth: UserAuthService,
                private zone: NgZone) {
        super();
        this.userAuth.user.pipe(takeUntil(this.destroy$)).subscribe(user => this.user = user);
    }

    enter(tooltip: NgbTooltip) {
        this.zone.run(() => tooltip.open());
    }

    leave(tooltip: NgbTooltip) {
        this.zone.run(() => tooltip.close());
    }

    onActionClick(event, action: HoverAction<T>) {
        event.stopPropagation();
        this.zone.run(() => {
            action.callback(this.data);
            this.selfDestroy$?.next();
        });
    }

    @MethodCache('reference')
    shouldShowAction(action: HoverAction) {
        if (!this.hasPermissionForAction(action)) return of(false);
        if (!action.shouldShowAction) return of(true);

        return action.shouldShowAction();
    }

    isActive(action: HoverAction) {
        return action.active ? action.active() : of(false);
    }

    private hasPermissionForAction(action: HoverAction) {
        if (!action.permissions) return true;

        return action.permissions.some(permission => this.user.permissions.includes(permission));
    }
}
