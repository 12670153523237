import {ChangeDetectorRef, Directive, ElementRef, Input, NgZone, Renderer2, ViewContainerRef} from '@angular/core';
import {HoverActionIconsComponent} from '../components/hover-action-icons/hover-action-icons.component';
import {HoverAction} from '../hover-action.interface';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {forkJoin, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {HoverComponentDirective} from './hover-component.directive';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Directive({
    selector: '[hoverActions]',
})
export class HoverActionsDirective<T = any> extends HoverComponentDirective<T> {
    private _actions: HoverAction[];

    get actions() {
        return this._actions;
    }

    @Input('hoverActions') set actions(x) {
        this._actions = x;
    }

    constructor(el: ElementRef,
                vc: ViewContainerRef,
                renderer: Renderer2,
                authService: UserAuthService,
                zone: NgZone,
                slidePanelService: SlidePanelService,
                modalService: NgbModal,
                router: Router,
                private cdref: ChangeDetectorRef) {
        super(el, vc, renderer, authService, zone, slidePanelService, modalService, router);
        if (this.position != 'top-right') this.position = 'top-right';
        // Implementing the default value to component property
        if (!this.component) this.component = HoverActionIconsComponent;
    }

    createComponent(beforeRender?: () => void) {
        if (!this.actions || !this._user || this.componentRef || !this.component) return;

        const hasContent$ = forkJoin(
            this.actions
                .filter(action => this._hasEnoughPermissionForAction(action))
                .map(action => action.shouldShowAction ? action.shouldShowAction() : of(true))
        ).pipe(map(showableArr => showableArr.some(x => x)));

        hasContent$.subscribe(hasContent => {
            if (!hasContent) return;

            super.createComponent(beforeRender);
            if (!this.componentRef?.instance) return;

            this.componentRef.instance.actions = this.actions;
            this.componentRef.instance.data = this.data;
            this.cdref.detectChanges();
        });
    }

    private _hasEnoughPermissionForAction(action: HoverAction): boolean {
        return !action.permissions || !action.permissions.length || action.permissions.some(permission => this._user.permissions.includes(permission));
    }
}
