<ng-container *ngFor="let action of actions">
    <ng-container *ngIf="shouldShowAction(action) | async">
        <button #t="ngbTooltip" [ngbTooltip]="action.caption"
                (click)="onActionClick($event, action)"
                (mouseenter)="enter(t)"
                (mouseleave)="leave(t)"
                class="btn btn-{{action.colorClass}} btn-circle-xs"
                [attr.data-test-id]="action.dataTestId">
            <fa [i]="action.icon" [variant]="(isActive(action) | async) ? 'solid' : 'light'"></fa>
        </button>
    </ng-container>
</ng-container>
